body {
  font-family: 'Jost';

  background: rgb(255, 102, 207, 0.4);
  background-size: 400% 400%;
}

.header {
  width: 100%;
  height: 100%;
}

.header__logo {
  background-color: #fff;
  margin: 0 50px;
  border-radius: 15px;
  text-align: center;
}

.header__logo img {
  margin: auto;
}

@media (max-width: 768px) {
  .header__logo {
    margin: 0 20px;
  }
}
