.main {
  display: grid;
  grid-auto-flow: column dense;
  grid-template-columns: 1.3fr 0.7fr;
  grid-template-rows: 1fr;
  gap: 0px 20px;
  grid-template-areas: '. .';
  margin: 0 50px;
}

.container {
  margin-top: 15px;
  margin-bottom: 20px;
}

.main__video {
  background-color: #fff;
  padding: 3% 5%;
  border-radius: 15px;
}

.main__contacts {
  width: 100%;
  border-radius: 15px;
  background-color: #fff;
}

/* LINKS STYLES */

.main__links {
  height: 100%;
  display: grid;
  grid-auto-flow: column dense;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'link__wildberries'
    'link__ozon'
    'link__letual'
    'link__whatsapp';
  justify-items: center;
  overflow-wrap: break-word;
}
.link__wildberries {
  margin-top: 20px;
  justify-self: center;
  align-self: center;
  grid-area: link__wildberries;
  background: linear-gradient(90deg, #dc45ec 0%, #60117e 100%);
}
.link__ozon {
  justify-self: center;
  align-self: center;
  grid-area: link__ozon;
  background: #2259f4;
}

.link__letual {
  justify-self: center;
  align-self: center;
  grid-area: link__letual;
  background: #2a3881;
}

.link__whatsapp {
  margin-bottom: 20px;
  justify-self: center;
  align-self: center;
  grid-area: link__whatsapp;
  background: #219e43;
}

.main__links_wrapper {
  border-radius: 5px;
  text-decoration: none;
  width: 80%;
}

/* BUTTON STYLES */
.main__btn {
  color: white;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5% 0;
}

/* MOBILE */

@media (max-width: 768px) {
  .main {
    grid-template-columns: 1fr;
    gap: 20px 0px;
    grid-template-areas:
      '.'
      '.';
    margin: 0 20px;
    margin-top: 20px;
  }

  .main__contacts {
    padding: 20px 0;
  }

  .main__links {
    gap: 10px 0px;
  }

  .main__btn {
    font-size: 1rem;
  }

  .main__contacts {
    margin-bottom: 30px;
  }
}
